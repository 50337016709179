import React from 'react';

// External Components
import { Section, FlexWrapper, Box } from '@thepuzzlers/pieces';

// Local Components
import {
  ImageContainer,
  VerticalLine,
  HeadlineBlock,
  ImageCover
} from 'components';

// Animations
import { scaleImageHeight, animateFadeUp, slideImage } from './animations';

export const Header = ({
  data: { imagesLandscape, imagesPortrait },
  cmsData: { headline }
}) => {
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...sectionRef.current.children[0].children];

    const killAnimation = slideImage(elements);

    return killAnimation;
  }, []);

  return (
    // Markup
    <Section id="services-header" ref={sectionRef}>
      <Images
        imagesLandscape={imagesLandscape}
        imagesPortrait={imagesPortrait}
      />
      <Headline data={headline} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => {
  const elementRef = React.useRef(null);

  return (
    <HeadlineBlock
      ref={elementRef}
      text={data}
      sx={{
        mt: ['12.54%', '10.86%', '9.05%', '6.07%', '8.01%', '7.18%']
      }}
      // animation value
      variants={animateFadeUp}
      initial="initial"
      animate="animate"
    />
  );
};

const Images = ({ imagesLandscape, imagesPortrait }) => (
  <ImagesWrapper>
    <SmallerScreenImages images={imagesLandscape} />
    <BiggerScreenImages images={imagesPortrait} />
  </ImagesWrapper>
);

const ImagesWrapper = ({ children }) => (
  <FlexWrapper
    className="services-header__images-wrapper"
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      overflow: 'hidden',
      justifyContent: 'space-between',
      position: 'relative'
    }}
    // Animation values
    initial="initial"
    animate="animate">
    {children}
  </FlexWrapper>
);

// Phone Portrait, Phone Portrait XL and Tablet Portrait images

const SmallerScreenImage = ({ src, alt, sx, ...props }) => (
  <ImageContainer
    className="images-wrapper__smaller-screen-image"
    src={src}
    alt={alt}
    sx={{
      aspectRatio: ['1.5/1', '1.5/1', '1.5/1', null, null, null],
      borderRadius: '2px',
      display: ['block', 'block', 'block', 'none', 'none', 'none'],
      overflow: 'hidden',
      width: '100%',
      ...sx
    }}
    imgProps={{}}
    {...props}
  />
);

const SmallerScreenImages = ({ images }) => (
  <>
    {images.map((img, index) => (
      <SmallerScreenImage
        key={`smaller-screen-images-${index}`}
        {...img}
        sx={{
          position: index < images.length - 1 ? 'absolute' : 'relative',
          zIndex: index
        }}
      />
    ))}
  </>
);

// Phone Landscape, Tablet Landscape and Desktop images

const BiggerSreenImage = ({ src, alt, custom }) => (
  <Box
    className="images-wrapper__bigger-screen-image"
    sx={{
      display: ['none', 'none', 'none', 'block', 'block', 'block'],
      position: 'relative',
      width: [null, null, null, '30.5%', '30.1%', '28.3%']
    }}>
    <ImageContainer
      src={src}
      alt={alt}
      sx={{
        aspectRatio: [null, null, null, '2/3', '2/2.8', '2/3'],
        borderRadius: '2px',
        overflow: 'hidden'
      }}
    />
    <ImageCover
      initial="initial"
      animate="animate"
      custom={custom}
      variants={scaleImageHeight}
      style={{ originY: 1 }}
    />
  </Box>
);

const BiggerScreenImages = ({ images }) => {
  const customDelay = [0, 0.3, 0.1];

  return images.map((img, index) => (
    <React.Fragment key={`bigger-screen-image-${index}`}>
      <BiggerSreenImage {...img} custom={customDelay[index]} />
      {index !== images.length - 1 && (
        <VerticalLine
          // Animation values
          initial="animate"
          animate="initial"
          variants={scaleImageHeight}
          style={{ originY: 0 }}
          animateLine={false} // disable gsap animation
          sx={{ display: ['none', 'none', 'none', 'block', 'block', 'block'] }}
        />
      )}
    </React.Fragment>
  ));
};
