import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { SectionSpacer, ListTitle, ListText, ListWrapper } from 'components';

export const Services = ({ cmsData: { services } }) => {
  return services.map((service, index) => (
    <React.Fragment key={service.title}>
      {index !== 0 && (
        <SectionSpacer
          spacing={['50.79%', '34.90%', '25.74%', '17.44%', '20.02%', '20.32%']}
        />
      )}
      <ServiceWrapper key={service.title} data={service} />
    </React.Fragment>
  ));
};

const ServiceWrapper = ({ data: { title, services } }) => (
  <Section className="services__service-wrapper">
    <ListTitle className="service-wrapper__title" text={title} />
    <ListWrapper>
      {services.map((item) => (
        <ListText
          key={item.name}
          variant="bold"
          sx={{ py: ['7.52%', '6.20%', '4.53%', '2.91%', '3.20%', '3.26%'] }}>
          {item.name}
        </ListText>
      ))}
    </ListWrapper>
  </Section>
);
