import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { useServicesPageData, Header, Services } from 'sections/servicesPage';
import { SectionSpacer, ParagraphSection } from 'components';
import { useNavOverlayAnimation } from 'context/NavOverlayAnimationContext';

// cms data
import { useCmsServicesPageData } from 'sections/servicesPage/useCmsServicePageData';

const ServicesPage = () => {
  const { seo, header } = useServicesPageData();
  const { isAnimating } = useNavOverlayAnimation();
  const {
    header: cmsHeader,
    textBlock: cmsTextBlock,
    services: cmsServices
  } = useCmsServicesPageData();

  if (!isAnimating)
    return (
      <>
        <SEO {...seo} />
        <Header data={header} cmsData={cmsHeader.currentLanguageData} />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '24.03%', '18.76%']}
        />
        <ParagraphSection cmsData={cmsTextBlock.currentLanguageData} />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '24.03%', '18.76%']}
        />
        <Services cmsData={cmsServices.currentLanguageData} />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '24.03%', '18.76%']}
        />
      </>
    );
  return null;
};

export default injectIntl(ServicesPage);
