import { gsap } from 'gsap';

export const scaleImageHeight = {
  initial: {
    scaleY: 1,
    transition: { type: 'tween', duration: 1.2, ease: 'anticipate' }
  },
  animate: (custom) => ({
    scaleY: 0,
    transition: {
      delay: custom,
      type: 'tween',
      duration: 1.2,
      ease: 'anticipate'
    }
  })
};

export const animateFadeUp = {
  initial: {
    y: '40%',
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1
    }
  }
};

export const slideImage = (elements) => {
  const setElements = (a, b) => {
    gsap.set(elements[a], { zIndex: 3 });
    gsap.set(elements[b], { clearProps: 'all' });
  };

  // Timeline
  let tl = gsap.timeline({
    repeat: -1,
    defaults: { xPercent: -100, duration: 3, ease: 'Power3.easeInOut' }
  });

  tl.to(elements[2], {
    onStart: () => {
      setElements(2, 0);
    }
  })
    .to(elements[1], {
      onStart: () => {
        setElements(1, 1);
      }
    })
    .to(elements[0], {
      onStart: () => {
        setElements(0, 2);
      }
    });

  return () => {
    tl.pause(0).kill(true);
  };
};
