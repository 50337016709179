import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useServicesPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: servicesDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          imagesLandscape {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imagesPortrait {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      de: deServicesDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          imagesLandscape {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imagesPortrait {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  return data[locale];
};
