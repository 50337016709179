import { graphql, useStaticQuery } from 'gatsby';

// helper
import { useGetCurrentLanguageCmsData } from 'sections/helper';

export const useCmsServicesPageData = () => {
  const { getCurrentLanguageData } = useGetCurrentLanguageCmsData();

  const {
    allRestApiPublicPagesServicesEntries,
    allRestApiPublicContentTypesServiceListEntries
  } = useStaticQuery(graphql`
    query CmsServicePageData {
      allRestApiPublicPagesServicesEntries {
        nodes {
          data {
            name
            key
            contentTypes {
              name
              key
              entries {
                entry {
                  headline
                  description {
                    html
                  }
                  services {
                    id
                  }
                }
                translations {
                  en {
                    headline
                    description {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
      allRestApiPublicContentTypesServiceListEntries {
        nodes {
          data {
            id
            entry {
              title
              services {
                name
              }
            }
            translations {
              en {
                services {
                  name
                }
                title
              }
            }
          }
        }
      }
    }
  `);

  const servicePageContent =
    allRestApiPublicPagesServicesEntries.nodes[0].data.contentTypes;
  const servicesDetailData =
    allRestApiPublicContentTypesServiceListEntries.nodes[0].data;

  /**
   * Merge the data to fill the missing translation
   */

  // helper function
  const getServiceDetailById = (id) =>
    servicesDetailData.find((serviceItem) => serviceItem.id === id);

  // merge service page content with service list detail data and create language selector
  const currentLanguageContentData = servicePageContent.map(
    (sectionContent) => {
      if (sectionContent.key !== 'offered-services')
        return {
          ...sectionContent,
          currentLanguageData: getCurrentLanguageData(sectionContent.entries[0])
        };
      return {
        ...sectionContent,
        currentLanguageData: {
          ...getCurrentLanguageData(sectionContent.entries[0]),
          services: sectionContent.entries[0].entry.services.map((service) => {
            const serviceListDetailDataById = getServiceDetailById(service.id);
            return {
              ...getCurrentLanguageData(serviceListDetailDataById)
            };
          })
        }
      };
    }
  );

  return {
    header: currentLanguageContentData[0],
    textBlock: currentLanguageContentData[1],
    services: currentLanguageContentData[2]
  };
};
